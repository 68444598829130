export const TABLE_PROPS = 'CLIENTS::TABLE_PROPS';

export const LOADING_GET_COMPLETED_GROUPS = 'CLIENTS::LOADING_GET_COMPLETED_GROUPS';
export const RECEIVE_GET_COMPLETED_GROUPS = 'CLIENTS::RECEIVE_GET_COMPLETED_GROUPS';
export const ERROR_GET_COMPLETED_GROUPS = 'CLIENTS::ERROR_GET_COMPLETED_GROUPS';

export const LOADING_GET_INCOMPLETE_GROUPS = 'GROUPS::LOADING_GET_INCOMPLETE_GROUPS';
export const RECEIVE_GET_INCOMPLETE_GROUPS = 'GROUPS::RECEIVE_GET_INCOMPLETE_GROUPS';
export const ERROR_GET_INCOMPLETE_GROUPS = 'GROUPS::ERROR_GET_INCOMPLETE_GROUPS';

export const LOADING_GET_GROUP = 'GROUPS::LOADING_GET_GROUP';
export const RECEIVE_GET_GROUP = 'GROUPS::RECEIVE_GET_GROUP';
export const ERROR_GET_GROUP = 'GROUPS::ERROR_GET_GROUP';

export const LOADING_SAVE_GROUP = 'GROUPS::LOADING_SAVE_GROUP';
export const RECEIVE_SAVE_GROUP = 'GROUPS::RECEIVE_SAVE_GROUP';
export const ERROR_SAVE_GROUP = 'GROUPS::ERROR_SAVE_GROUP';

export const LOADING_EDIT_GROUP_COMPLETED = 'GROUPS::LOADING_EDIT_GROUP_COMPLETED';
export const RECEIVE_EDIT_GROUP_COMPLETED = 'GROUPS::RECEIVE_EDIT_GROUP_COMPLETED';
export const ERROR_EDIT_GROUP_COMPLETED = 'GROUPS::ERROR_EDIT_GROUP_COMPLETED';

export const LOADING_SAVE_SCHEDULE = 'GROUPS::LOADING_SAVE_SCHEDULE';
export const RECEIVE_SAVE_SCHEDULE = 'GROUPS::RECEIVE_SAVE_SCHEDULE';
export const ERROR_SAVE_SCHEDULE = 'GROUPS::ERROR_SAVE_SCHEDULE';

export const LOADING_EDIT_SCHEDULE = 'GROUPS::LOADING_EDIT_SCHEDULE';
export const RECEIVE_EDIT_SCHEDULE = 'GROUPS::RECEIVE_EDIT_SCHEDULE';
export const ERROR_EDIT_SCHEDULE = 'GROUPS::ERROR_EDIT_SCHEDULE';

export const LOADING_ASSIGN_TEACHER = 'GROUPS::LOADING_ASSIGN_TEACHER';
export const RECEIVE_ASSIGN_TEACHER = 'GROUPS::RECEIVE_ASSIGN_TEACHER';
export const ERROR_ASSIGN_TEACHER = 'GROUPS::ERROR_ASSIGN_TEACHER';

export const LOADING_ASSIGN_MSACLASS_TEACHER = 'GROUPS::LOADING_ASSIGN_MSACLASS_TEACHER';
export const RECEIVE_ASSIGN_MSACLASS_TEACHER = 'GROUPS::RECEIVE_ASSIGN_MSACLASS_TEACHER';
export const ERROR_ASSIGN_MSACLASS_TEACHER = 'GROUPS::ERROR_ASSIGN_MSACLASS_TEACHER';

export const LOADING_ACTIVATE_GROUPS = 'GROUPS::LOADING_ACTIVATE_GROUPS';
export const RECEIVE_ACTIVATE_GROUPS = 'GROUPS::RECEIVE_ACTIVATE_GROUPS';
export const ERROR_ACTIVATE_GROUPS = 'GROUPS::ERROR_ACTIVATE_GROUPS';

export const INCOMPLETE_GROUP_ROW_SELECTION = 'GROUPS::INCOMPLETE_GROUP_ROW_SELECTION';

export const ONE_INCOMPLETE_GROUP_ROW_SELECTION = 'GROUPS::ONE_INCOMPLETE_GROUP_ROW_SELECTION';

export const LOADING_SET_TEACHER_TARIFF = 'GROUPS::LOADING_SET_TEACHER_TARIFF';
export const RECEIVE_SET_TEACHER_TARIFF = 'GROUPS::RECEIVE_SET_TEACHER_TARIFF';
export const ERROR_SET_TEACHER_TARIFF = 'GROUPS::ERROR_SET_TEACHER_TARIFF';

export const LOADING_ASSIGN_MATERIALS = 'GROUPS::LOADING_ASSIGN_MATERIALS';
export const RECEIVE_ASSIGN_MATERIALS = 'GROUPS::RECEIVE_ASSIGN_MATERIALS';
export const ERROR_ASSIGN_MATERIALS = 'GROUPS::ERROR_ASSIGN_MATERIALS';

export const LOADING_GET_TYPE_EVIDENCE = 'GROUPS::LOADING_GET_TYPE_EVIDENCE';
export const RECEIVE_GET_TYPE_EVIDENCE = 'GROUPS::RECEIVE_GET_TYPE_EVIDENCE';
export const ERROR_GET_TYPE_EVIDENCE = 'GROUPS::ERROR_GET_TYPE_EVIDENCE';

export const RESET_LOADING_STATE = 'GROUPS::RESET_LOADING_STATE';