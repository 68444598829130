import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupCompleted, assignMSAClassTeacher } from "../../../reducers/groups/actions";
import * as Actions from "../../../reducers/schedule/actions";
import { setActiveKey } from "../../../containers/Classes/actions";
import { getEvents } from "../../../reducers/group-event/actions";
import EditTeacherAssignationForm from "../EditTeacherAssignationForm";
import moment from 'moment';

import "./EditTeacherAsignationModal.scss";

function EditTeacherAsignationModal(props) {

  const {
    scheduleReducer,
    groupsReducer,
    getGroupCompleted,
    startDate,
    match: { params },
  } = props;

  const { catalogReasonEdit } = scheduleReducer;

  const { group } = groupsReducer;

  const { id: groupId } = params;

  const [values, setValues] = useState({
    start_date: moment(startDate, 'DD-MM-YYYY'),
    end_date: moment(startDate, 'DD-MM-YYYY'),
  });

  const [form] = Form.useForm();

  useEffect(() => {
    groupId && getGroupCompleted(groupId);
  }, [groupId]);

  useEffect(() => {
    form.setFieldsValue({
      start_date: moment(startDate, 'DD-MM-YYYY'),
      end_date: moment(startDate, 'DD-MM-YYYY'),
    });
  }, [group]);

  return (
    <EditTeacherAssignationForm
      {...props}
      group={group}
      form={form}
      values={values}
      setValues={setValues}
      reasonCatalog={catalogReasonEdit}
      groupId={groupId}
    />
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Actions, getGroupCompleted, assignMSAClassTeacher, getEvents, setActiveKey }, dispatch);
const mapStateToProps = (state) => ({
  scheduleReducer: state.get("scheduleReducer"),
  groupsReducer: state.get("groupsReducer"),
  groupEventReducer: state.get("groupEventReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeacherAsignationModal);
